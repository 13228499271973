import React, {useState, useContext} from 'react'
import {Container, Row, Col, Form} from 'react-bootstrap';
import BarHeader from './common/BarHeader';
import CountryOptions from './helpers/enums/countryOptions';
import Popup from './common/Popup';
import './register.css';

import registerIcon from './img/register-user-icon.png'
import {FaEye, FaUserMd, FaUserPlus} from 'react-icons/fa'
import {BsMailbox} from 'react-icons/bs'

import { registerNewAccount } from './services/userServices';
import { logOut } from './services/Authentication';

import { UserContext } from './UserContext';

export default function Register() {

    const userData = useContext(UserContext);
    if(!userData === null){
        logOut();
    }
    
    //States for registration
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    //User registered PopUp
    const [showPopUp, setShowPopUp] = useState(false);

    const[errorMsg, setErrorMsg] = useState("")
    async function handleSumbitButton(){
        var res = await registerNewAccount(email, password, password2, country, setErrorMsg);
        if(await res){
            setShowPopUp(true);
        }
    }

    function showPasswords(){
        document.getElementById("password").type = 'text';
        document.getElementById("password2").type = 'text';
    }

    return (
        <div>
            <BarHeader/>
            <div className='register' id="register">
                <Container className='registerContainer styledBox'>
                    <Row className='noMargin'>
                        <Col xs={12} sm={3}>
                            <Row style={{marginBottom: '0.7vh'}}>
                                <span className='registerIconText textCenter'>
                                    Register New<br></br>Account
                                </span>
                            </Row>
                            <Row>
                                <img src={registerIcon} alt='registerIcon' className='registerIcon'/>
                            </Row>
                        </Col>
                        <Col xs={12} sm={9} className='registerFormCol vCenter'>
                            <Form onSubmit={e => { e.preventDefault(); }}>
                                <Form.Group className='registerFormGroup'>
                                    <Row>
                                        <span>Enter Your Credentials:</span>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="email" value={email} 
                                            placeholder={'Email'} className='registerInput'
                                            onChange={(e) => 
                                                {      
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select value={country} 
                                            style={{fontStyle: (country==="Country")? 'italic' : null,
                                            color: (country==="Country")? '#062e5bcf' : null}}
                                            className='registerInput'
                                            onChange={(e) => 
                                                {      
                                                    setCountry(e.target.value);
                                                }}
                                            >
                                                <CountryOptions/>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <div style={{position: 'relative'}}>
                                                <Form.Control type="password" value={password} 
                                                    id='password'
                                                    placeholder={'Password'} className='registerInput'
                                                    onChange={(e) => 
                                                        {      
                                                            setPassword(e.target.value);
                                                        }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <div style={{position: 'relative'}}>
                                                <Form.Control type="password" value={password2} 
                                                    id='password2'
                                                    placeholder={'Repeat Password'} className='registerInput'
                                                    onChange={(e) => 
                                                        {      
                                                            setPassword2(e.target.value);
                                                        }}
                                                    />
                                                <span 
                                                    style={{position: 'absolute', right: '0', cursor: 'pointer', 
                                                    fontSize: '12pt'}}
                                                    onClick={() => showPasswords()}
                                                >
                                                    <FaEye/> Show passwords
                                                </span>
                                                <span>&nbsp;</span>
                                            </div>
     
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row className='registerButtonCol vCenter'>
                                    <div className='registerBtn' id='registerBtn'
                                    onClick={() => handleSumbitButton()}>
                                        <span className='hCenterFlex'>
                                            <div className='vCenter'>
                                                <FaUserPlus className='registerBtnIcon'/>
                                            </div>
                                            <span style={{marginTop: '0.2vh'}}>Register</span>
                                        </span>
                                    </div>
                                    <span className='registerErrorText'>{errorMsg}</span>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                    <div className='suggestionSpan'>
                        Already have an account? <a href='/login'>Log In</a>
                    </div>
                </Container>
            </div>

            <Popup 
            showPopUp={showPopUp}
            onClick={() => window.location.replace("/")}
            Icon={<FaUserMd style={{marginBottom: '0.65vh'}}/>}
            Title={"Welcome!"}
            Body={
                <span> 
                    <b>Your user has been registered successfully! <b><a href='/login'>Click here to Log In</a></b></b>
                    <br></br><br></br>Also, We sent an email to your address in order to confirm your email address. 
                    <br></br><b>It is possible that the email is in your spam folder or that is has been filtered by your email provider. If you have any problems, dont hesitate to contact us.</b>
                    <br></br><br></br>Find our contact information and learn more about this site in the <a href='/about'>About Us</a> section.
                </span>}
            />
        </div>
    )

}
