import React, { useState } from 'react'
import './resetPopup.css';
import { Form } from 'react-bootstrap';
import {IoKeyOutline} from 'react-icons/io5';
import { requestPasswordReset } from './services/userServices';

export default function ResetPopup({showPopUp, setShowPopup}) {

    const [statusMsg, setStatusMsg] = useState("");
    const [email, setEmail] = useState("");

    async function handleAcceptClick(){
        var result = await requestPasswordReset(email);
        if(result){
            setStatusMsg("Success! We sent a link to " + email + " to reset your password. If you have problems don't hesitate to contact us. You can find our contact information in the 'About Us' section.");
            document.getElementById("resetPwdButton").disabled = true;
        }else{
            setStatusMsg("Invalid email, please try again.")
        }
    }

    if(showPopUp){
        return(
            <div className='resetPopup' id='resetPopup'>
                <div className='overlay'></div>
                
                <div className='popupContent styledBox'>
                    <div className='popupTitle'>
                        <IoKeyOutline className='popupIcon'/>
                        &nbsp;Reset password
                    </div>
                    <div className='popupMessage'>
                        <span>
                            Please enter your account's email to reset your password:
                        </span>
                        <Form.Control
                        type="email" value={email} 
                        placeholder={'Email'} className='resetPasswordInput'
                        onChange={(e) => 
                            {      
                                setEmail(e.target.value);
                            }}
                        />
                        <span className='statusSpan'>
                           {statusMsg}
                        </span>
                    </div>
                    <div className='resetButtons'>
                        <button className='resetPopupBtn popupBtn'
                        onClick={() => setShowPopup(false)}>
                            Cancel
                        </button>
                        <button className='resetPopupBtn popupBtn' id='resetPwdButton'
                        onClick={() => handleAcceptClick()}>
                            Accept
                        </button>
                    </div>                    
                </div>
            </div>
        )
    }
}
